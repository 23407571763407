import * as fb from '../../firebase/index.js'

export default {
  state: {
    userRoles: [],
    userRole: null
  },
  getters: {
    userRoles: state => state.userRoles,
    userRole: state => state.userRole
  },
  mutations: {
    setUserRoles(state, userRoles) {
      state.userRoles = userRoles;
    },
    setUserRole(state, role) {
      state.userRole = role
    }
  },
  actions: {
    getUserRoles({ commit, getters }) {
      let roles = [];
      return new Promise((resolve, reject) => {
        fb.userRolesCollection.onSnapshot(snapshot => {
          snapshot.docs.forEach(role => {
            let index = getters.userRoles.findIndex(userRole => userRole.id === role.id)
            if (index !== -1) {
              getters.userRoles.splice(index, 1)
            }
            roles.push({ data: role.data(), id: role.id });
          })
          commit('setUserRoles', roles);
          commit('setUserRole', getters.userProfile.data().userRole)
          console.log('Fetched all User Roles', roles)
          resolve('Fetched all User Roles')

          
        })
      })
    },
    addNewRole({ dispatch }, role) {
      return new Promise((resolve, reject) => {
        const newRole = {
          name: role.name,
          description: role.description,
          permissions: role.permissions,
          dateCreated: new Date(),
          lastUpdated: new Date(),
          userCount: 0
        };
        fb.userRolesCollection.add(newRole)
          .then(() => {
            dispatch('getUserRoles');
            resolve('New role added successfully')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    updateRole({ dispatch }, role) {
      return new Promise((resolve, reject) => {
        fb.userRolesCollection.doc(role.id).update({
          name: role.name,
          description: role.description,
          lastUpdated: new Date(),
          permissions: role.permissions
        })
          .then(() => {
            dispatch('getUserRoles');
            resolve('User role updated successfully.');
          })
          .catch(error => {
            reject(error.message)
          })
      })
    },
    deleteRole({ dispatch }, roleId) {
      return new Promise((resolve, reject) => {
        fb.userRolesCollection.doc(roleId).delete()
          .then(() => {
            dispatch('getUserRoles');
            resolve('Role deleted successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    createDefaultRoles({ dispatch }) {
      dispatch('addNewRole', {
        name: 'Super Admin',
        description: 'This is the super Admin with all level of Access',
        permissions: {
          config: true,
          roles: true,
          user: {
            add: true,
            read: true,
            write: true,
            delete: true
          },
          kiosk: {
            add: true,
            read: true,
            write: true,
            delete: true
          },
          orgGrp: {
            add: true,
            read: true,
            write: true,
            delete: true
          }
        }
      })
      dispatch('addNewRole', {
        name: 'General',
        description: 'This role is assigned to the user created using authentication service',
        permissions: {
          config: false,
          roles: false,
          user: {
            add: false,
            read: true,
            write: false,
            delete: false
          },
          kiosk: {
            add: false,
            read: true,
            write: false,
            delete: false
          },
          orgGrp: {
            add: false,
            read: true,
            write: false,
            delete: false
          }
        }
      })
      dispatch('addNewRole', {
        name: 'Admin',
        description: 'This role is assigned to the user created using authentication service',
        permissions: {
          config: false,
          roles: true,
          user: {
            add: true,
            read: true,
            write: true,
            delete: true
          },
          kiosk: {
            add: true,
            read: true,
            write: true,
            delete: true
          },
          orgGrp: {
            add: true,
            read: true,
            write: true,
            delete: true
          }
        }
      })
    }
  }
}