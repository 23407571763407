<template>
    <v-dialog
      max-width="500px"
      max-height="300px"
      v-model="getIncomingCallDialog.show"
      persistent
    >
      <v-card class="pa-3" height="230px" rounded>
        <p class="text-center font-weight-medium">Receiving A Call From</p>
  
        <v-row v-if="getIncomingCallDialog.meeting.length === 1">
          <v-col align="center" cols="12">
            <p class="text-center headline font-weight-light">
              {{ getIncomingCallDialog.meeting[0].initiatorName }}
            </p>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              class="mx-2"
              fab
              dark
              large
              color="green"
              @click="acceptCall(getIncomingCallDialog.meeting[0])"
            >
              <v-icon dark>mdi-phone</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              class="mx-2"
              fab
              dark
              large
              color="red"
              @click="declineCall(getIncomingCallDialog.meeting[0])"
            >
              <v-icon dark>mdi-phone-hangup</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col
            v-for="(meet, i) in getIncomingCallDialog.meeting"
            :key="i"
            cols="12"
          >
            <v-card width="100%" flat>
              <v-row>
                <v-col cols="6">
                  <span>{{ meet.callerInfo }}</span>
                </v-col>
                <v-col cols="3">
                  <v-btn fab dark color="green" @click="acceptCall(meet)">
                    <v-icon dark>mdi-phone</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn fab dark color="red" @click="declineCall(meet)">
                    <v-icon dark>mdi-phone-hangup</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <div class="pt-3">
          <v-progress-linear
            indeterminate
            reverse
            striped
            background-opacity="0"
            color="red darken-2"
          ></v-progress-linear>
  
          <v-progress-linear
            indeterminate
            striped
            background-opacity="0"
            color="green"
          ></v-progress-linear>
  
          <v-progress-linear
            indeterminate
            reverse
            striped
            background-opacity="0"
            color="red darken-2"
          ></v-progress-linear>
  
          <v-progress-linear
            indeterminate
            striped
            background-opacity="0"
            color="green"
          ></v-progress-linear>
        </div>
      </v-card>
      <audio id="myAudio"></audio>
    </v-dialog>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  
  export default {
    name: "incomingCall",
    props: ["caller"],
    data() {
      return {
        dialog: false,
        callerName: "",
        callState: null,
        dialogTimer: null,
        callAccepted: false,
        userProfileCallObj: this.userProfile,
      };
    },
    computed: {
      ...mapGetters([
        "userProfile",
        "getIncomingCallDialog",
        "getIncomingCallCount",
      ]),
    },
    methods: {
      acceptCall(meetInfo) {
        this.$store.dispatch("acceptCallRequest", meetInfo).then(() => {
          this.removeMeetingFromList(meetInfo, "accept");
        });
      },
  
      declineCall(meetInfo) {
        this.removeMeetingFromList(meetInfo, "reject");
        if (meetInfo.callType === "Single") {
          this.$store.dispatch("rejectCallRequest", {
            meetInfo: meetInfo,
            meetCount: this.getIncomingCallDialog.meeting.length,
          });
        } else {
          this.$store.dispatch("rejectGroupCall", {
            meetInfo: meetInfo,
            meetCount: this.getIncomingCallDialog.meeting.length,
          });
        }
      },
      removeMeetingFromList(meetInfo, action) {
        var index = this.getIncomingCallDialog.meeting.findIndex(
          (meeting) => meeting.meetingId === meetInfo.meetingId
        );
        this.getIncomingCallDialog.meeting.splice(index, 1);
        if (action === "accept") {
          this.getIncomingCallDialog.meeting.forEach((meet) => {
            this.declineCall(meet);
          });
        }
        this.$store.commit("closeIncomingCallDialog");
      },
      closeDialog() {
        clearTimeout(this.dialogTimer);
        this.dialog = false;
      },
    },
    mounted() {
      //console.log("Call Info Details: ", this.getIncomingCallDialog);
    },
  };
  </script>
  
  <style>
  </style>