<template>
  <div>
    <navBar></navBar>
    <drawer></drawer>
  </div>
</template>

<script>

import navBar from './navBar/navBar';
import { mapGetters } from 'vuex';
import Drawer from './navDrawer/drawer.vue';


export default {
  name: 'navigation',
  components: {
    navBar,
    Drawer
  },
  computed: {
    ...mapGetters(['userProfile', 'isAdmin', 'isSuperAdmin']),
  }

}
</script>