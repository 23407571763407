<template>
    <v-navigation-drawer color="white" permanent clipped-left app>
        <v-app-bar flat>
            <!-- <span class="text-h5 justify-center">El Paso, Texas</span> -->
            <v-card height="100%" flat tile class="d-flex align-center justify-center pa-2">
                <v-img src="@/assets/logo.png" contain justify="center">
                </v-img>
            </v-card>
        </v-app-bar>
        <v-divider></v-divider>
        <v-card height="75%" flat tile class="overflow-y-auto pa-0">
            <v-list dense v-if="isAdmin || isSuperAdmin">
                <v-list-item @click="navigate('/')">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Home </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-group prepend-icon="mdi-account-circle" no-action :value="true">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title> Manage </v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item v-for="item in manage" :key="item.title" @click="navigate(item.link)">
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title"> </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-divider></v-divider>
                </v-list-group>

                <v-divider></v-divider>
                <v-list-item @click="navigate('/configureClient')" v-if="isSuperAdmin">
                    <v-list-item-icon>
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Configure Client </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="navigate('/kioskSimulator')">
                    <v-list-item-icon>
                        <v-icon>mdi-mushroom</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Kiosk Simulator </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
            </v-list>
            <v-list v-else dense>
                <v-list-item @click="navigate('/')">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Home </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
    </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex';
import orgGroupList from '../orgGroupList.vue';
import router from '../../../router';
export default {
    name: 'drawer',
    computed: {
        ...mapGetters(['appData', 'isSuperAdmin', 'isAdmin', 'userProfile'])
    },
    components: {
        orgGroupList
    },
    data() {
        return {
            selectedItem: 1,
            isHomePage: true,
            manage: [
                {
                    title: "Users",
                    icon: "mdi-account-multiple-outline",
                    link: "/userManagement",
                },
                {
                    title: "Organization Groups",
                    icon: "mdi-account-group-outline",
                    link: "/groupManagement",
                },
                {
                    title: "Roles",
                    icon: "mdi-card-account-details-outline",
                    link: "/roleManagement",
                },
                {
                    title: "Kiosks",
                    icon: "mdi-presentation-play",
                    link: "/kioskManagement",
                },
            ],
            analytics: [
                {
                    title: "Kiosk",
                    icon: "mdi-presentation-play",
                    link: "/kioskAnalytics",
                }
            ],
        }
    },
    methods: {
        navigate(link) {
            if (router.currentRoute.path === "/liveCall") {
                window.open(link, "_blank");
            } else {
                router.push(link);
            }
        },
    },
    mounted() {
        if (typeof window !== 'undefined') {
            // Use window.location.pathname to determine the current path
            this.isHomePage = router.currentRoute.path === '/kioskUpdates';
        }
    }
}
</script>