<template>
    <div>
      <v-dialog
        max-width="500px"
        max-height="300px"
        v-model="getOutgoingCallDialog.show"
        persistent
        v-if="getOutgoingCallDialog.addToExisting"
      >
        <v-card class="pa-3" height="230px" rounded>
          <p class="text-center font-weight-medium">
            {{ getOutgoingCallDialog.callText }}
          </p>
          <p class="text-center headline font-weight-light">
            {{ getOutgoingCallDialog.callerInfo }}
          </p>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                class="mx-2"
                fab
                dark
                large
                color="red"
                @click="$store.dispatch('cancelCall', getOutgoingCallDialog)"
              >
                <v-icon dark>{{ getOutgoingCallDialog.callIcon }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="getOutgoingCallDialog.inCall">
              <v-progress-linear
                indeterminate
                stream
                reverse
                background-opacity="0.2"
                color="primary"
              ></v-progress-linear>
              <v-progress-linear
                indeterminate
                stream
                background-opacity="0.2"
                color="primary"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="getOutgoingCallDialog.show" v-else app bottom right timeout="-1" height="100">
        {{ getOutgoingCallDialog.callText }}
        {{ getOutgoingCallDialog.callerInfo }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="$store.dispatch('cancelCall', getOutgoingCallDialog)">
            <v-icon>mdi-phone-hangup</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    name: "outgoingCall",
  
    data() {
      return {
        //
      };
    },
    computed: {
      ...mapGetters(["getOutgoingCallDialog"]),
    },
    methods: {},
  };
  </script>
  
  <style>
  </style>