<template>
    <v-dialog v-model="getCallStatusDialog.show" persistent max-width="25%">
        <v-card width="100%">
            <v-card-title class="justify-center mb-5">{{ getCallStatusDialog.message }}</v-card-title>
            <v-card-actions class="justify-center">
                <v-btn @click="closeCallStatusDialog()" width="125" color="error">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "callStatus",
    computed: {
        ...mapGetters(['getCallStatusDialog'])
    },
    methods: {
        closeCallStatusDialog() {
            this.$store.commit("setCallStatusDialog", {
                show: false
            })
        }
    }
}
</script>