import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.getOutgoingCallDialog.addToExisting)?_c(VDialog,{attrs:{"max-width":"500px","max-height":"300px","persistent":""},model:{value:(_vm.getOutgoingCallDialog.show),callback:function ($$v) {_vm.$set(_vm.getOutgoingCallDialog, "show", $$v)},expression:"getOutgoingCallDialog.show"}},[_c(VCard,{staticClass:"pa-3",attrs:{"height":"230px","rounded":""}},[_c('p',{staticClass:"text-center font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getOutgoingCallDialog.callText)+" ")]),_c('p',{staticClass:"text-center headline font-weight-light"},[_vm._v(" "+_vm._s(_vm.getOutgoingCallDialog.callerInfo)+" ")]),_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"mx-2",attrs:{"fab":"","dark":"","large":"","color":"red"},on:{"click":function($event){return _vm.$store.dispatch('cancelCall', _vm.getOutgoingCallDialog)}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.getOutgoingCallDialog.callIcon))])],1)],1),(_vm.getOutgoingCallDialog.inCall)?_c(VCol,{attrs:{"cols":"12"}},[_c(VProgressLinear,{attrs:{"indeterminate":"","stream":"","reverse":"","background-opacity":"0.2","color":"primary"}}),_c(VProgressLinear,{attrs:{"indeterminate":"","stream":"","background-opacity":"0.2","color":"primary"}})],1):_vm._e()],1)],1)],1):_c(VSnackbar,{attrs:{"app":"","bottom":"","right":"","timeout":"-1","height":"100"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.$store.dispatch('cancelCall', _vm.getOutgoingCallDialog)}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-phone-hangup")])],1)]}}]),model:{value:(_vm.getOutgoingCallDialog.show),callback:function ($$v) {_vm.$set(_vm.getOutgoingCallDialog, "show", $$v)},expression:"getOutgoingCallDialog.show"}},[_vm._v(" "+_vm._s(_vm.getOutgoingCallDialog.callText)+" "+_vm._s(_vm.getOutgoingCallDialog.callerInfo)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }