import * as fb from "../../firebase";
import router from "../../router";
export default {
    state: {
        enableTelepresence: false,
        callStatusDialog: {},
        meetingUrl: {}
    },
    getters: {
        getEnableTelepresence: state => state.enableTelepresence,
        getCallStatusDialog: state => state.callStatusDialog,
        getMeetingUrl: state => state.meetingUrl
    },
    mutations: {
        setEnableTelepresence(state, status) {
            state.enableTelepresence = status
        },
        setCallStatusDialog(state, callStatusObj) {
            state.callStatusDialog = callStatusObj
        },
        setMeetingUrl(state, meetingObj) {
            state.meetingUrl = meetingObj
        }
    },
    actions: {
        telepresencePermission({ commit, getters }, status) {
            fb.registeredUsersCollection.doc(getters.userId).update({
                telepresence: status
            }).then(() => {
                commit('setEnableTelepresence', status)
            })
        },
        endCall({ commit, dispatch, getters }) {
            router.push("/");
            dispatch("setUserOffCall");
            commit("setMeetingCode", null);
            commit("setIncomingCallCount", 0);
            if (getters.getMeetingUrl.initiator === getters.userProfile.data().name) {
                // Stop listing to that event
                getters.getCallInitiateListener();
                dispatch("endExistingCalls", getters.getOutgoingCall);
            } else {
                //this.$store.dispatch("endExistingCalls", this.getOutgoingCall);
                dispatch("stopListener", getters.getMeetingUrl.meetingId);
            }
        },
        endExistingCalls({ dispatch, getters }, meetings) {
            console.log('Meetingds : ', meetings)
            meetings.forEach(meetingId => {
                fb.telepresenceCollection.doc(meetingId).get().then(meetings => {
                    let index = getters.getOutgoingCall.findIndex(
                        (meeting) => meeting === meetingId
                    );
                    getters.getOutgoingCall.splice(index, 1);
                    console.log(' Existing Meetings: ', meetings.data())
                    if (meetings.data().callStatus !== 'Initiated') {
                        if (meetings.data().requestedUser === null || meetings.data().requestedUser.length === 0) {
                            dispatch("endCallRequest", meetingId)
                        } else {
                            if (meetings.data().source) {
                                fb.telepresenceCollection.doc(meetingId).update({
                                    source: false
                                })
                            } else {
                                fb.telepresenceCollection.doc(meetingId).update({
                                    callStatus: 'Ended',
                                    requestedUser: null
                                })
                            }

                        }
                    }
                })
            })
        },
        stopListener({ getters }, meet) {
            console.log('Meet: ', meet)
            fb.telepresenceCollection
                .doc(meet).get().then(call => {
                    console.log('Call: ', call)
                    if (!call.data().source) {
                        fb.telepresenceCollection.doc(meet).update({
                            callStatus: 'Ended',
                            requestedUser: null,
                        })
                    } else {
                        fb.telepresenceCollection.doc(meet).update({
                            requestedUser: null,
                        })
                    }
                })
        },
    }
}