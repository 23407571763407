import * as fb from "@/firebase/index.js";
import moment from "moment";
const crypto = require("crypto");
import axios from "axios";
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    pullSessionFromStrapi({ getters }) {
      let data;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://cms.lancing.arsconnect.com/user-sessions?actionType=Session&_limit=-1",
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios.request(config).then((response) => {
        console.log("Response from user Session", response.data);
        response.data.forEach((doc) => {
          if (doc.sessionPayload.action === "User Left") {
            data = {
              actionType: "Session Ended",
            };
          } else {
            data = {
              actionType: "Session Started",
            };
          }
          let config2 = {
            method: "put",
            maxBodyLength: Infinity,
            url: "https://cms.lancing.arsconnect.com/user-sessions/" + doc.id,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios.request(config2).then((response) => {
            console.log("Done");
          });
        });
      });
    },
    fetchSessionsFromFB({ getters }) {
      const startDate = new Date("2024-10-01");
      const endDate = new Date("2024-11-31");
      fb.userSessionCollection
        .where("userSessionStartTime", ">", startDate)
        .where("userSessionStartTime", "<", endDate)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let sessionId = crypto.randomBytes(50).toString("hex").slice(0, 15);
            let userSession = JSON.parse(doc.data().userSessionLogs);
            userSession.forEach((session) => {
              let data = {
                applicationId: "ARS-STAGE-LANSING",
                applicationSessionId: sessionId,
                actionType: session.requestType,
                timeStamp: moment(session.timeStamp).toISOString(),
                sessionPayload: session,
              };
              console.log("Modified Session Data/");
              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://cms.lancing.arsconnect.com/user-sessions",
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };
              axios
                .request(config)
                .then((response) => {
                  console.log("Session Added.");
                })
                .catch((error) => {
                  console.log("Error Adding Session");
                });
            });
          });
        });
    },
    migrateQnaIntoSession({ getters }) {
      let qnas = [];
      let config1 = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://cms.lancing.arsconnect.com/user-sessions?actionType=Speech&_limit=-1",
        headers: {},
      };
      axios.request(config1).then((response1) => {
        console.log("From STrapi: ", response1.data);
        fb.qnaCollection
          .get()
          .then((response) => {
            response.docs.forEach((doc) => {
              console.log("Firebase value: ", doc.data());
              let found = response1.data.filter(
                (bhoc) => bhoc.sessionPayload.action === doc.data().transcript
              );
              found.forEach((qna) => {
                console.log("QNA found", qna);
                let config2 = {
                  method: "put",
                  maxBodyLength: Infinity,
                  url:
                    "https://cms.lancing.arsconnect.com/user-sessions/" +
                    qna.id,
                  data: {
                    sessionPayload: {
                      action: qna.sessionPayload.action,
                      module: "QnA",
                      requestType: "Speech",
                      response: doc.data().result,
                    },
                  },
                };
                console.log("Config 2 correct? ", config2);
                axios.request(config2).then((response) => {
                  console.lof("Response");
                });
              });
            });
          });
        //console.log("Response: ", response);
      });
    },
  },
};
